.tile-heading {
  padding: 1.5rem 0.6rem;
  background-color: #5e5b5b;
  color: white;
  text-align: center;
  white-space: nowrap;
}

.tile-image {
  height: 20vh;
}

.tile-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.tile a {
  text-decoration: none;
}

.tile a:hover {
  text-decoration: none;
}

.tile:hover .tile-heading {
  background-color: #82aae3;
}

.tiles {
  position: relative;
  top: -10vh;
  z-index: 10;
}

@media (max-width: 1200px) {
  .tile-heading {
    padding: 0.6rem;
    font-size: small;
  }
}

@media (max-width: 992px) {
  .tiles {
    position: static;
    margin-top: 2vh;
  }

  .tile {
    margin-bottom: 2vh;
  }

  .tile-heading {
    padding: 1.5rem;
    font-size: large;
  }
}
