.sidebar {
  background-color: rgb(235, 235, 235);
  height: 100%;
}

.header {
  padding: 1rem;
  margin: 0;
}

.header:hover {
  cursor: pointer;
  color: #82aae3;
}

.header:hover .header-link {
  color: #82aae3;
}

.header .arrow {
  float: right;
}

.content {
  padding: 0 3rem;
  margin-bottom: 1rem;
}

.sidebar a {
  text-decoration: none;
  color: #000;
}

.sidebar a:hover {
  color: #82aae3;
}

.active {
  color: #82aae3;
}
