.footer {
  /* position: absolute; */
  /* bottom: 0; */
  /* width: 100%; */
  /* margin-top: 1000px; */
  height: 140px;
  background-color: #eafdfc;
}

.logo-footer {
  width: 176px;
  height: 65px;
  position: absolute;
  right: 0;
  top: 10px;
}

.links {
  min-width: 70vw;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  top: 50px;
}

.links ul li {
  float: left;
  list-style-type: none;
  margin: 0 15px;
}

.links ul li a {
  text-decoration: none;
  color: #333;
}

.links ul li a:hover {
  color: #82aae3;
}

#copyright {
  position: absolute;
  top: 100px;
}

@media (max-width: 992px) {
  .logo-footer {
    width: 135px;
    height: 50px;
  }

  .links {
    top: 70px;
  }

  #copyright {
    top: 130px;
  }

  .footer {
    height: 160px;
  }
}

/* @media (min-height: 900px) and (max-height: 1200px) {
  .footer {
    position: absolute;
    bottom: 0;
    width: 100%;
  }
} */
