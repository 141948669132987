.left-image {
  width: 50%;
  float: left;
  margin-right: 20px;
}

@media (max-width: 992px) {
  .left-image {
    width: 100%;
    margin-bottom: 20px;
  }
}
