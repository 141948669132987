.navbar-custom {
  background-color: #eafdfc;
  padding: 0;
}

.navbar-nav {
  font-size: 1.3rem;
  text-align: center;
}

.navbar-nav > li > a.active {
  color: #82aae3 !important;
}

.nav-link:hover {
  text-decoration: none !important;
}

.dropdown:hover > .dropdown-menu {
  display: block;
}

.dropdown > .dropdown-toggle:active {
  pointer-events: none;
}

.switch-lang {
  width: 110px;
  text-align: left;
  cursor: pointer;
  z-index: 50;
  position: absolute;
  top: 20px;
  right: 20px;
}

.switch-lang:hover .lang-dropdown {
  display: block;
}

.current-lang {
  background: #34495e;
  padding: 3px 5px 0px 5px;
  border-radius: 5px;
  height: 40px;
  color: #fff;
}

.lang-flag {
  width: 20px;
  display: inline-block;
}

.lang-text {
  display: inline-block;
  margin-left: 5px;
  vertical-align: top;
  margin-top: 2px;
}

.lang-dropdown {
  display: none;
  background: #34495e;
  border-radius: 5px;
  margin-top: 2px;
  height: 40px;
}

.selecting-lang {
  padding: 3px 5px 3px 5px;
  cursor: pointer;
}

.selecting-lang:hover {
  background: #22313f;
  height: 40px;
}

.switcher-link {
  color: #fff;
  text-decoration: none;
}

@media (max-width: 992px) {
  .navbar .navbar-nav {
    display: inline-block;
    float: none;
    vertical-align: top;
    width: 100%;
  }

  .navbar .navbar-collapse {
    text-align: center;
  }

  #logo {
    width: 203px;
    height: 75px;
  }

  .navbar-nav > li > a {
    border-bottom: 2px solid #82aae3;
  }

  .navbar-nav > li > a:hover {
    color: #82aae3;
  }

  .switch-lang {
    position: static;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0.6rem;
  }
}

@media (min-width: 992px) {
  .navbar-nav {
    margin-right: 10rem;
    height: 102px;
  }

  .navbar-nav > li > a {
    padding: 0;
    margin: 0 1.2rem;
    vertical-align: bottom;
    border-bottom: 5px solid transparent;
  }

  .navbar-nav > li > a:hover {
    color: #82aae3;
    border-bottom: 5px solid #82aae3;
  }
}
