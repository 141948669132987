/* .carousel-item {
  height: 300px;
} */

.carousel-item img {
  object-fit: cover;
  object-position: center;
  overflow: hidden;
  height: 50vh;
  margin: 0;
}

.carousel-indicators button {
  width: 10px !important;
  height: 10px !important;
  border-radius: 100%;
}

.carousel-indicators button.active {
  background-color: #0067f8 !important;
}

.carousel-indicators {
  bottom: 9vh;
}

@media (max-width: 992px) {
  .carousel-indicators {
    bottom: 0;
  }
}
