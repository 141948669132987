.box-wrap {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin: 5%;
}

.box {
  padding: 30px;
  margin: 2%;
  background: white;
  display: block;
  cursor: pointer;
  width: 30%;
  border: 1px solid #d6d6d6;
  box-shadow: 0 2px 3px 0px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  transition: 0.2s all;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.box.hover {
  filter: blur(3px);
  opacity: 0.5;
  transform: scale(0.98);
  box-shadow: none;
}

.box:hover {
  transform: scale(1);
  filter: blur(0px);
  opacity: 1;
  box-shadow: 0 8px 20px 0px rgba(0, 0, 0, 0.125);
}

.milky {
  font-family: "Arial Rounded MT Bold", "Helvetica Rounded", Arial, sans-serif;
  text-transform: uppercase;
  font-size: 3.3rem;
  color: #a5a19c;
  text-shadow: 0 8px 9px #c4b59d, 0px -2px 1px #fff;
  font-weight: bold;
  letter-spacing: -4px;
  padding-top: 1rem;
}

@media (max-width: 992px) {
  .box-wrap {
    flex-direction: column;
  }
}
