.collapsible .content {
  padding: 6px 15px;
  background-color: #eeeeee;
}

.collapsible .header {
  background-color: #dddddd;
  padding: 6px;
  cursor: pointer;
}

.mainContainer {
  padding: 1.4rem;
  text-align: justify;
  font-family: Arvo, sans-serif;
}

.mainContainer h1 {
  text-align: center;
  letter-spacing: 2px;
  margin-bottom: 15px;
}

.whole-content {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

h2,
h3 {
  margin-top: 1rem;
}

.content-name,
.content-image {
  text-align: center;
}

.main-image {
  width: 80%;
  height: 80%;
  object-fit: fill;
}

.section-header {
  border-top: 2px dashed black;
  border-bottom: 2px dashed black;
  padding-bottom: 5px;
  font-size: 2rem;
}

.check-list {
  margin: 0;
  padding-left: 1.2rem;
}

.check-list li {
  position: relative;
  list-style-type: none;
  padding-left: 2.5rem;
  margin-bottom: 0.1rem;
  font-size: 1rem;
}

.check-list p {
  font-size: 1rem;
}

.check-list li:last-child {
  margin-bottom: 1rem;
}

.check-list li:before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 4px;
  width: 5px;
  height: 11px;
  border-width: 0 2px 2px 0;
  border-style: solid;
  border-color: #00a8a8;
  transform-origin: bottom left;
  transform: rotate(45deg);
}

p {
  font-size: large;
  margin-top: 1.2rem;
  margin-bottom: 1.2rem;
}

.services {
  background: #f1f1f1;
  font-family: "Inconsolata", monospace;
  font-weight: bold;
  color: gray;
  text-align: center;
  -webkit-font-smoothing: antialiased;
}

.services a {
  display: block;
}

.company {
  background: #f1f1f1;
  font-family: "Inconsolata", monospace;
  font-weight: bold;
  color: gray;
}

.contact {
  padding: 15px;
}

@media (max-width: 768px) {
  .col-md-3 {
    padding: 0;
  }

  .content-image {
    margin-top: 1rem;
  }
}
