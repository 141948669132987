.maps {
  border: 0;
  width: 100%;
  height: 100%;
}

.contact-info {
  text-align: left;
}

.contact-info p {
  font-size: 1.5rem;
}
